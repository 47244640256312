window.onload = function() {
  // Select the div with tcpa-disclosure class
  const tcpaDisclosureDiv = document.querySelector('.tcpa-disclosure');
  if (tcpaDisclosureDiv !== null) {
      // Get the innerHTML of the div, remove HTML tags, asterisk, newline characters, extra spaces and trim
      let text = tcpaDisclosureDiv.innerHTML.replace(/(<([^>]+)>)/gi, "").replace('*', "").replace(/\r?\n|\r/g, " ").replace(/\s\s+/g, " ").trim();
      // Create SHA-256 hash using CryptoJS
      const hash = CryptoJS.SHA256(text).toString(CryptoJS.enc.Hex);
      // uncomment below for testing
      // console.log(text);
      // alert('here is my hash: ' + hash);

      // Get the form on the page
      const form = document.querySelector('form');
      // Create hidden input field, set its name and value, and append to the form
      if (form !== null) {
          const input = document.createElement('input');
          input.type = 'hidden';
          input.name = '_certificationUri';
          input.value = hash;
          form.appendChild(input);
      }
  }
}
